import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { AdsActions } from '../actions'
// @NOTE: re-enable this when you want to test the native ad
// import { parseWallpaperData } from '../components/Ads/External/Wallpaper/Wallpaper.helpers'
// import mockData from '../components/Ads/External/Wallpaper/Wallpaper.mock'
import { ParsedVoyoSlider } from '../helpers/middleware/parseVoyoSlider'

const { setNativeAdData, modifyNativeAdData } = AdsActions

export type NativeAdType = 'wallpaper' | 'converger' | 'convergerNew' | 'inscreen' | 'revealer'

export const useNativeAdDispatch = (nativeType: NativeAdType) => {
    const dispatch = useDispatch()
    const dispatchNativeAd = useCallback(
        (data: any, overwrite = true) =>
            dispatch((overwrite ? setNativeAdData : modifyNativeAdData)({ nativeType, data })),
        [dispatch, nativeType]
    )

    return dispatchNativeAd
}
export const useCustomFilledStateDispatch = (key?: string | null) => {
    const dispatch = useDispatch()
    const dispatchCustomFilledState = useCallback(
        (isFilled: boolean) => key && dispatch(AdsActions.setCustomFilledState(key, isFilled)),
        [dispatch, key]
    )
    return dispatchCustomFilledState
}
export const useVoyoDataDispatch = () => {
    const dispatch = useDispatch()
    const dispatchVoyoData = useCallback(
        (data: { [key: string]: ParsedVoyoSlider }) => dispatch(AdsActions.setVoyoData(data)),
        [dispatch]
    )
    return dispatchVoyoData
}

export const useInarticleMountedDispatch = () => {
    const dispatch = useDispatch()
    return useCallback(() => dispatch(AdsActions.inarticleAdMounted()), [dispatch])
}
