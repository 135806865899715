/* eslint-disable @typescript-eslint/no-unused-vars */
const width = '1200'
const height = '800'
const mockDataConverger: any = {
    height,
    width,
    fileImage: 'https://tpc.googlesyndication.com/pagead/imgad?id=CICAgJCYsf_JWRABGAEyCNYt6vBbCBeN',
    fileMobileImage: 'https://tpc.googlesyndication.com/pagead/imgad?id=CICAgJCY8ZC__gEQARgBMgg2cgDhXmP0bA',
    imageBackgroundColor: '#92461e',
    effect: 'Slide', // Scale, Slide
    hasGlobalClickTag: 'YES',
    clickUrl: 'https://net.hr',
    clickTagName: 'clickTAG',
    clickUrlUnesc:
        'https://adclick.g.doubleclick.net/pcs/click?xai=AKAOjstlhUvpyr8P-j7UBLogMTUdOwyRfNmHif0ormtFvlCS3Y5BI_0T2GYF8gHUCRoXDKnNBjUAaUgOjlG_sXPOkVIpkyQWMFwQfLs3GbemhQW3vORkaAPzPmGNcwWl4M_QgITAGrPeuQ2YBNqZqlBJkJq9RNGt-krdx7dolAV43f_Izaru9zkKdL6H4zIq712mMbebkugQ3LF-NutLSGUUPOhutL_YSycaq3cbdP8g86sadPlLZ2I1Xr79si78Gen2uIxVb2Tq010XSg5Qr3Q6FOSy0rD0J4zfCNsN486LCRGzaxao9_GZTWE4qQVYvdXVgdg&sai=AMfl-YT4qdbQGsbkRP41CitXsw_y1pHFCUiDwo_9XU0xWFS6bmQ5kruL1BkA5ZC_pf0yP23FWngn44LhJEyA_MLzRVgAzDU2MZcKgN926G4WvIi6iUImpAfDPs6SIzZFhtLwP9lbEBTjnoVfxDPVTY01&sig=Cg0ArKJSzPZZHzJNXgjMEAE&fbs_aeid=[gw_fbsaeid]&urlfix=1&adurl=',
    openInNewTab: 'YES',
    sizingElementId: '',
    thirdPartyImpressionUrl: '',
    viewUrlUnesc:
        'https://securepubads.g.doubleclick.net/pcs/view?xai=AKAOjsspQdanVseM4bwFpSDNimHw3nFRoxL5w1_vy_CQHP6rSe3NgJQc_H9gArq61ZaaA-PRTFubgG5EaIFYpPjzxRmYfgxwLEAV4TScCJbrI0-9OAIEimKeSBQP167SnjCJjZMbvQ-3iXRXTYpFOOgLsA8bvgGa131fnfdjsXB19Oez1SMP5_uws4tOmFmSKsEd98L9qiTU2sIamtmiGOvmvz_kAGqzJNrYBAz_kKpGASROq_Ke4wq6e20yV6xkldlRKR1E9DIQNexb0tHuzGKOH-C-1RsfevJeUKwuQZ92WKtvP3CChr714q5hhBlaqdOxpVtBM2JAHWU&sai=AMfl-YR2BmMOTolDI9F9imSnUB7Ewz7atP_eN90x-8VKg-BVpvAkG8PSN7ETHWdg7Wz4rTjRDzkQACLAjXeUEQBKBnJXi17hmmuuHYD0Dgd9r9VEkvlKwEgtoIOXurM3UdcZSU-w69vlvnTtmXcl6Wlu&sig=Cg0ArKJSzDS8uvJsEQn-EAE&uach_m=[UACH]&urlfix=1&adurl=',
    ad: {
        ADUNIT: '/21783803/rtl-test-oop-adunit-1',
        CACHEBUSTER: '1486187877',
        CLICK_URL_UNESC:
            'https://adclick.g.doubleclick.net/pcs/click?xai=AKAOjstlhUvpyr8P-j7UBLogMTUdOwyRfNmHif0ormtFvlCS3Y5BI_0T2GYF8gHUCRoXDKnNBjUAaUgOjlG_sXPOkVIpkyQWMFwQfLs3GbemhQW3vORkaAPzPmGNcwWl4M_QgITAGrPeuQ2YBNqZqlBJkJq9RNGt-krdx7dolAV43f_Izaru9zkKdL6H4zIq712mMbebkugQ3LF-NutLSGUUPOhutL_YSycaq3cbdP8g86sadPlLZ2I1Xr79si78Gen2uIxVb2Tq010XSg5Qr3Q6FOSy0rD0J4zfCNsN486LCRGzaxao9_GZTWE4qQVYvdXVgdg&sai=AMfl-YT4qdbQGsbkRP41CitXsw_y1pHFCUiDwo_9XU0xWFS6bmQ5kruL1BkA5ZC_pf0yP23FWngn44LhJEyA_MLzRVgAzDU2MZcKgN926G4WvIi6iUImpAfDPs6SIzZFhtLwP9lbEBTjnoVfxDPVTY01&sig=Cg0ArKJSzPZZHzJNXgjMEAE&fbs_aeid=[gw_fbsaeid]&urlfix=1&adurl=',
        CLICK_URL_ESC:
            'https://adclick.g.doubleclick.net/pcs/click%253Fxai%253DAKAOjstlhUvpyr8P-j7UBLogMTUdOwyRfNmHif0ormtFvlCS3Y5BI_0T2GYF8gHUCRoXDKnNBjUAaUgOjlG_sXPOkVIpkyQWMFwQfLs3GbemhQW3vORkaAPzPmGNcwWl4M_QgITAGrPeuQ2YBNqZqlBJkJq9RNGt-krdx7dolAV43f_Izaru9zkKdL6H4zIq712mMbebkugQ3LF-NutLSGUUPOhutL_YSycaq3cbdP8g86sadPlLZ2I1Xr79si78Gen2uIxVb2Tq010XSg5Qr3Q6FOSy0rD0J4zfCNsN486LCRGzaxao9_GZTWE4qQVYvdXVgdg%2526sai%253DAMfl-YT4qdbQGsbkRP41CitXsw_y1pHFCUiDwo_9XU0xWFS6bmQ5kruL1BkA5ZC_pf0yP23FWngn44LhJEyA_MLzRVgAzDU2MZcKgN926G4WvIi6iUImpAfDPs6SIzZFhtLwP9lbEBTjnoVfxDPVTY01%2526sig%253DCg0ArKJSzPZZHzJNXgjMEAE%2526fbs_aeid%253D%255Bgw_fbsaeid%255D%2526urlfix%253D1%2526adurl%253D',
        CLICK_URL_ESC_ESC:
            'https://adclick.g.doubleclick.net/pcs/click%253Fxai%253DAKAOjstlhUvpyr8P-j7UBLogMTUdOwyRfNmHif0ormtFvlCS3Y5BI_0T2GYF8gHUCRoXDKnNBjUAaUgOjlG_sXPOkVIpkyQWMFwQfLs3GbemhQW3vORkaAPzPmGNcwWl4M_QgITAGrPeuQ2YBNqZqlBJkJq9RNGt-krdx7dolAV43f_Izaru9zkKdL6H4zIq712mMbebkugQ3LF-NutLSGUUPOhutL_YSycaq3cbdP8g86sadPlLZ2I1Xr79si78Gen2uIxVb2Tq010XSg5Qr3Q6FOSy0rD0J4zfCNsN486LCRGzaxao9_GZTWE4qQVYvdXVgdg%2526sai%253DAMfl-YT4qdbQGsbkRP41CitXsw_y1pHFCUiDwo_9XU0xWFS6bmQ5kruL1BkA5ZC_pf0yP23FWngn44LhJEyA_MLzRVgAzDU2MZcKgN926G4WvIi6iUImpAfDPs6SIzZFhtLwP9lbEBTjnoVfxDPVTY01%2526sig%253DCg0ArKJSzPZZHzJNXgjMEAE%2526fbs_aeid%253D%255Bgw_fbsaeid%255D%2526urlfix%253D1%2526adurl%253D'
    },
    thirdPartyTagsHtml:
        '<img src="https://iprom.net/log/impression/?time=[timestamp]&m=RTL&sid=net_hr&ssid=staging_net&adid=1080805&z=299" style="display: none;">'
}

export default mockDataConverger
