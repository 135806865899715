import { useMemo } from 'react'
import { useUIDSeed } from 'react-uid'

const getAdKey = (positionConfig, prefix = 'inline-ad') => {
    const positionPart = (
        Array.isArray(positionConfig.position) ? positionConfig.position.join('-') : positionConfig.position
    )
        .replace(/ /gim, '-')
        .replace(/-+/gim, '-')
    const devicePart = (positionConfig.desktopOnly && 'desktop') || (positionConfig.mobileOnly && 'mobile') || 'all'
    return `${prefix}-${positionPart}-${devicePart}`
}

const useInArticleAds = ({ insertAdsConfig, AdSlotComponent, maxAdWidth, title }) => {
    const uid = useUIDSeed()

    return useMemo(() => {
        if (!insertAdsConfig) {
            return []
        }
        const arrayConfigs = Array.isArray(insertAdsConfig) ? insertAdsConfig : [insertAdsConfig]
        return arrayConfigs
            .filter(Boolean)
            .map(config => {
                const parsedElements =
                    config?.elements
                        ?.map(
                            slotConfig =>
                                slotConfig.position && (
                                    <AdSlotComponent
                                        {...slotConfig}
                                        key={uid(getAdKey(slotConfig))}
                                        maxAdWidth={maxAdWidth}
                                        title={title}
                                        isInarticle
                                        alternateBg
                                    />
                                )
                        )
                        .filter(Boolean) || []
                if (parsedElements.length === 0) {
                    return null
                }
                return {
                    ...config,
                    elements: parsedElements
                }
            })
            .filter(Boolean)
    }, [insertAdsConfig, AdSlotComponent, title, maxAdWidth])
}
export default useInArticleAds
export const useListingInsertAds = ({
    insertAdsConfig,
    AdSlotComponent,
    maxAdWidth,
    ColumnComponent,
    columnVariant = 'category.ads.listing'
}) => {
    const uid = useUIDSeed()

    return useMemo(() => {
        if (!insertAdsConfig) {
            return []
        }
        const arrayConfigs = Array.isArray(insertAdsConfig) ? insertAdsConfig : [insertAdsConfig]
        return arrayConfigs
            .map(config => {
                const parsedElements =
                    config.elements
                        ?.map(
                            slotConfig =>
                                slotConfig.position && (
                                    <ColumnComponent
                                        key={uid(getAdKey(slotConfig, 'listing-billboard-ad'))}
                                        variant={columnVariant}>
                                        <AdSlotComponent {...slotConfig} maxAdWidth={maxAdWidth} />
                                    </ColumnComponent>
                                )
                        )
                        .filter(Boolean) || []
                if (parsedElements.length === 0) {
                    return null
                }
                return {
                    ...config,
                    elements: parsedElements
                }
            })
            .filter(Boolean)
    }, [insertAdsConfig, columnVariant, maxAdWidth])
}
