import { FilledSlotsStore } from '../../context/ads/filled.context'
import { ZONE_NAME_SEPARATOR, ZONE_NAME_TARGETING_KEY } from '../../hooks/ads/zoneNameConstants'
import type {
    AdPositionLoaded,
    DisplaySlotsSettings,
    GoogleTagSlot,
    SlotDivId,
    ZoneName
} from '../../types/ads/Ad.interface'

export type MergedAdSlotDefinition = AdPositionLoaded & DisplaySlotsSettings
// @NOTE: you can be very specific by defining a query param, for now is just ssearch query for masturbacija, lol
const BANNED_SEARCH_TERMS_ZENA = ['masturbacija'] as const
// eslint-disable-next-line security-node/non-literal-reg-expr
const BANNED_SEARCH_TERMS_ZENA_REGEX = new RegExp(BANNED_SEARCH_TERMS_ZENA.join('|'), 'gim')
export const checkSearchQuery = (query: Record<string, string> = {}) => {
    const queries = Object.values(query)
    if (!queries?.length) {
        return true
    }
    return !queries.some(q => q.match(BANNED_SEARCH_TERMS_ZENA_REGEX))
}

// eslint-disable-next-line security-node/non-literal-reg-expr
const BANNED_SEARCH_PATH_ZENA_REGEX = new RegExp(`pretrazivanje\\?q=(${BANNED_SEARCH_TERMS_ZENA.join('|')})+`, 'gim')
export const checkAdsEnabledState = (appName, asPath) => {
    if (appName === 'zenahr' && asPath.match(BANNED_SEARCH_PATH_ZENA_REGEX)) {
        return false
    }
    return true
}

// const compareTargeting = (slot: GoogleTagSlot, config: MergedAdSlotDefinition):boolean => {
//     const categoryArgument = config?.targetingArguments?.nethr_category.join("-") || ""
//     const categoryArgumentOnSlot = slot.getTargeting("nethr_category")?.join("-") || ""
//     return categoryArgument === categoryArgumentOnSlot
// }

const compareAdUnit = (slot: GoogleTagSlot, config: MergedAdSlotDefinition): boolean =>
    config.adUnit === slot.getAdUnitPath().split('/').filter(Boolean).slice(1).join('/')

const compareDivId = (slot: GoogleTagSlot, config: MergedAdSlotDefinition): boolean =>
    config.divId === slot.getSlotElementId()

export const compareSlots = (
    zoneConfigs: Record<ZoneName, MergedAdSlotDefinition>,
    definedSlots: Record<SlotDivId, GoogleTagSlot>,
    dependantZonesMap: Record<ZoneName, ZoneName[]>,
    filledSlots: FilledSlotsStore,
    iteration = 0
) => {
    const slotsToDefine: MergedAdSlotDefinition[] = []
    const slotsToRefresh: MergedAdSlotDefinition[] = []
    const slotsToDisplay: SlotDivId[] = []
    const slotsToDestroy: SlotDivId[] = []

    const isFresh = iteration === 0
    const zoneNameDefinedSlotMap = Object.entries(definedSlots).reduce(
        (acc, [, slot]) => {
            const zoneName = slot.getTargeting(ZONE_NAME_TARGETING_KEY)?.join(ZONE_NAME_SEPARATOR)
            if (!zoneName) {
                return acc
            }
            acc[zoneName] = slot
            return acc
        },
        {} as Record<ZoneName, GoogleTagSlot>
    )

    const checkSameZoneExists = (zoneName: ZoneName, divId: SlotDivId) => {
        const definedSlot = definedSlots[divId]
        const zoneConfig = zoneConfigs[zoneName]
        return (
            !!definedSlot &&
            !!zoneConfig &&
            // compareTargeting(definedSlot,zoneConfig) &&
            compareDivId(definedSlot, zoneConfig) &&
            compareAdUnit(definedSlot, zoneConfig)
        )
    }

    const shouldWaitForDependantZone = (zoneName: ZoneName) => {
        const dependencies = dependantZonesMap?.[zoneName] || []
        if (!dependencies?.length) {
            return false
        }
        return dependencies.some(d => {
            const dependency = zoneConfigs[d]
            if (!dependency) {
                return false
            }
            if (shouldWaitForDependantZone(d)) {
                return true
            }
            const definedSlot = definedSlots[dependency.divId]
            const filledSlot = filledSlots[dependency.divId]
            const wasFilled = !!filledSlot?.filled
            if (definedSlot) {
                return typeof filledSlot === 'undefined' || wasFilled
            }
            const usedThisIteration =
                slotsToRefresh.some(s => s.divId === dependency.divId) ||
                slotsToDefine.some(s => s.divId === dependency.divId) ||
                slotsToDisplay.includes(dependency.divId)

            return usedThisIteration
        })
    }

    Object.entries(zoneConfigs)
        .map(entry => {
            const [zoneName, slotDefinition] = entry
            if (shouldWaitForDependantZone(zoneName as ZoneName)) {
                return entry
            }
            const divId = slotDefinition.divId
            if (!divId) return entry
            if (checkSameZoneExists(zoneName as ZoneName, divId)) {
                if (!isFresh) return entry
                slotsToRefresh.push(slotDefinition)
                return entry
            }
            slotsToDefine.push(slotDefinition)
            slotsToDisplay.push(divId)
            return entry
        })
        .forEach(([zoneName, slotDefinition]) => {
            if (shouldWaitForDependantZone(zoneName as ZoneName)) {
                const indexOfRefresh = slotsToRefresh.findIndex(s => s.divId === slotDefinition.divId)
                if (indexOfRefresh > -1) {
                    slotsToRefresh.splice(indexOfRefresh, 1)
                }
                const indexOfDefine = slotsToDefine.findIndex(s => s.divId === slotDefinition.divId)
                if (indexOfDefine > -1) {
                    slotsToDefine.splice(indexOfDefine, 1)
                }
                const indexOfDisplay = slotsToDisplay.findIndex(s => s === slotDefinition.divId)
                if (indexOfDisplay > -1) {
                    slotsToDisplay.splice(indexOfDisplay, 1)
                }
            }
        })

    if (!isFresh) {
        return { slotsToDefine, slotsToRefresh: [], slotsToDisplay, slotsToDestroy: [] }
    }

    Object.entries(zoneNameDefinedSlotMap).forEach(([zoneName, slot]) => {
        const id = slot.getSlotElementId()
        if (
            !checkSameZoneExists(zoneName as ZoneName, id) ||
            (isFresh && shouldWaitForDependantZone(zoneName as ZoneName))
        ) {
            slotsToDestroy.push(id)
        }
    })
    return { slotsToDefine, slotsToRefresh: slotsToRefresh.map(s => s.divId), slotsToDisplay, slotsToDestroy }
}
