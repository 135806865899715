import Script from 'next/script'
import PropTypes from 'prop-types'

import { createDidomiConnectedScriptHandler } from '../createDidomiConnectedScriptHandler'

function Marfeel({ token = 8336 }) {
    if (typeof window !== 'undefined' && window.marfeel) {
        return null
    }

    const html = createDidomiConnectedScriptHandler(
        '943',
        `
        !function(){"use strict";function e(e){var t=!(arguments.length>1&&void 0!==arguments[1])||arguments[1],c=document.createElement("script");c.src=e,t?c.type="module":(c.async=!0,c.type="text/javascript",c.setAttribute("nomodule",""));var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(c,n)}!function(t,c){!function(t,c,n){var a,o,r;n.accountId=c,null!==(a=t.marfeel)&&void 0!==a||(t.marfeel={}),null!==(o=(r=t.marfeel).cmd)&&void 0!==o||(r.cmd=[]),t.marfeel.config=n;var i="https://sdk.mrf.io/statics";e("".concat(i,"/marfeel-sdk.js?id=").concat(c),!0),e("".concat(i,"/marfeel-sdk.es5.js?id=").concat(c),!1)}(t,c,arguments.length>2&&void 0!==arguments[2]?arguments[2]:{})}(window,${token},{} /* Config */)}();
        `
    )

    // console.log('mrf create', token)
    return (
        <Script
            id="marfeelSDK"
            key="marfeelSDK"
            defer
            strategy="lazyOnload"
            dangerouslySetInnerHTML={{
                __html: html
            }}
        />
    )
}

Marfeel.propTypes = {
    token: PropTypes.string
}

Marfeel.defaultProps = {
    token: undefined
}

export default Marfeel
